import React, { useState, useRef } from "react";
import { images } from "../constants";
import "./Contact.css";

const Contact = () => {
  return (
    <section className="contact">
      <div className="contact__header">
        <div className="contactHeader__content">
          <h1 className="contactHeader__title animate__animated animate__zoomIn ">
            Kontakt
          </h1>
        </div>
        <div className="image__gradient"></div>
      </div>
      <div className="contact__container">
        <div className="contact__content">
          <h3 className="animate__animated animate__fadeIn">
            Behöver du hjälp ?
          </h3>
          <h2 className="headingTwo animate__animated animate__fadeIn">
            Kontakta oss
          </h2>
          <p className="text animate__animated animate__fadeIn">
            Har du frågor om våra tjänster och hur vi kan hjälpa dig? Fyll gärna
            i formuläret nedan, så återkommer vi inom 1-2 arbetsdagar. Du är
            också varmt välkommen att kontakta oss via e-post eller telefon.
          </p>
          <div className="contact-details animate__animated animate__zoomIn ">
            <div className="contactInfo-container">
              <div className="contact-col">
                <img src={images.telefonikon} alt="telefon-icon" />
                <div className="contact__info">
                  <div className="contact__sub-title">
                    <h4>Telefon</h4>
                    <a href="tel:+46 (0) 76 005 77 60">+46 (0) 76 005 77 60</a>
                  </div>
                </div>
              </div>
              <div className="contact-col">
                <img src={images.mailikon} alt="mail-icon" />
                <div className="contact__info">
                  <div className="contact__sub-title">
                    <h4>Mail</h4>
                    <a
                      className="email__link"
                      href="mailto:Info@rldeskservice.se"
                    >
                      Info@rldeskservice.se
                    </a>
                  </div>
                </div>
              </div>
              <div className="contact-col">
                <img
                  className="opening-hours"
                  src={images.tiderikon}
                  alt="openingHours-icon"
                />
                <div className="contact__info">
                  <div className="contact__sub-title">
                    <h4 className="opening-hours"> Våra öppettider</h4>
                  </div>
                  <p>
                    Måndag-Torsdag: 10.00 - 15.00 <br /> Fredag: 11.00 - 15.00{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="form-content">
          <iframe
            id="JotFormIFrame-232174181065350"
            title="Contact form"
            onload="window.parent.scrollTo(0,0)"
            allowtransparency="true"
            allowfullscreen="true"
            allow="geolocation; microphone; camera"
            src="https://form.jotform.com/232174181065350"
            frameborder="0"
            scrolling="no"
          >
            {" "}
          </iframe>
        </div>
      </div>
    </section>
  );
};

export default Contact;
