import React from "react";
import { Link } from "react-router-dom";
import { images } from "../constants";
import "./About.css";

const About = () => {
  return (
    <section className="about">
      <div className="about__header">
        <div className="about__content">
          <h1 className="aboutHeader__title animate__animated animate__zoomIn ">
            Om oss
          </h1>
          <p className="aboutHeader__text animate__animated animate__fadeInUp animate__slow	1s">
            Experter inom administration. Anlita oss för att få jobbet gjort.
          </p>
        </div>
        <div className="image__gradient"></div>
      </div>

      <div className="aboutUs__info">
        <div className="aboutUs__infoContent">
          <h2 className="headingTwo animate__animated animate__fadeIn">
            RL Deskservice
          </h2>
          <p className=" text animate__animated animate__fadeIn">
            RL Deskservice AB är ett konsultföretag som erbjuder
            administrativa tjänster inom en mängd olika områden till företag,
            privatpersoner, egenanställda och kreatörer. Våra
            tjänster skräddarsys efter våra kunders behov. Vår kombinerade
            erfarenhet inom administration, HR, juridik, CV skrivning och
            rekrytering uppgår mot drygt 30 år.
          </p>
          <h4>Vårt mål</h4>
          <p className="text">
            Vårt mål är att underlätta för företag, privatpersoner,
            kreatörer och egenanställda att fokusera på sitt arbete, sin vardag
            och sin passion medan vi tar hand om det administrativa.
            Vi vill göra det enkelt för alla att få stöd med administrativa 
            uppgifter oavsett förutsättningar.
          </p>
          <h4>Vad är det som gör oss unika?</h4>
          <p className="text">
            Det som gör vårt företag unikt är att vi erbjuder administrativ
            assistans till alla, oavsett behov. Till skillnad från många andra 
            aktörer, som ofta riktar sina tjänster mot en specfik målgrupp, 
            hjälper vi till oavsett i vilken egenskap du kontaktar oss eller vilka
            behov du har. inget uppdrag är för litet eller för stort för oss på RL 
            Deskservice. Vårt främsta fokus är alltid kunden, och vi strävar efter 
            att leverera en personlig och våra kunder ska få en personlig och god serivce.
          </p>

          <div className="cv-pb__signImg">
            <img src={images.rl} alt="" />
          </div>
        </div>
      </div>

      <div className="about-sectionTwo">
        <h2 className="headingTwo">Varför välja oss?</h2>
        <div className="about-sectionTwo__container">
          <div className="about-textContentTwo">
            <div className="about-flexContent">
              <div className="checkicon">
                <img src={images.checkicon} alt="" />
              </div>
              <div className="about-textBox">
                <h3>Snabb och god service</h3>
                <p className="text">
                  Vår prioritering är först och främst alltid kunden och att kunna
                  erbjuda den bästa möjliga servicen. Vi svarar vanligen inom 1-2 dagar.
                </p>
              </div>
            </div>
            <div className="about-flexContent">
              <div className="checkicon">
                <img src={images.checkicon} alt="" />
              </div>
              <div className="about-textBox">
                <h3>Personlig service</h3>
                <p className="text">
                  Vi erbjuder alla våra kunder en personlig upplevelse genom att
                  tilldela en fast kontaktperson på företaget. Vi håller våra kunder
                  uppdaterade om alla steg vi tar för att främja deras
                  intressen. Trots att våra tjänster till största del erbjuds
                  digitalt, så ser vi till att alla våra kunder känner sig väl
                  omhändertagna och får ett bra och personligt bemötande.
                </p>
              </div>
            </div>
            <div className="about-flexContent">
              <div className="checkicon">
                <img src={images.checkicon} alt="" />
              </div>
              <div className="about-textBox">
                <h3>Omfattande kompetens</h3>
                <p className="text">
                  Vi erbjuder en bred kompetens inom diverse områden, bland
                  annat administration, juridik, rekrytering, skattefrågor,
                  kundsupport, influencer marketing frågor, egenanställning och
                  marknadsföring.
                </p>
              </div>
            </div>
            <div className="about-flexContent">
              <div className="checkicon">
                <img src={images.checkicon} alt="" />
              </div>
              <div className="about-textBox">
                <h3>Gedigen erfarenhet</h3>
                <p className="text">
                  Vårt team har omfattande erfarenhet inom branschen och 
                  strävar ständigt efter att vidareutbilda oss och utveckla 
                 vår kompetens för att alltid vara uppdaterade med nya 
                 regler och lagar samt kunna leverera resultat av högsta kvalitet.
                </p>
              </div>
            </div>

            <div className="about-extraText">
              <p>
                Vid frågor eller funderingar tveka inte att höra av er så gör vi
                vårt bästa för att tillgodose era behov och komma fram till en
                lösning som passar er.
              </p>
            </div>
          </div>
          <div className="about-imgBox">
            <img src={images.aboutus2} alt="" />
          </div>
        </div>
      </div>

      <div className="about-sectionTwo__mobile">
        <div className="about-imgBox">
          <img src={images.aboutus2} alt="" />
        </div>
        <div className="about-textContentTwo">
          <h1 className="about-textTitle  animate__animated animate__zoomIn">
            Varför välja oss ?
          </h1>
          <div className="about-flexContent">
            <div className="checkicon">
              <img src={images.checkicon} alt="" />
            </div>
            <div className="about-textBox">
              <h3>Snabb och god service</h3>
              <p className="text">
                Vår prioritering är först och främst alltid kunden och att kunna
                erbjuda den bästa möjliga servicen. Vi svarar vanligen inom 1-2 dagar.
              </p>
            </div>
          </div>
          <div className="about-flexContent">
            <div className="checkicon">
              <img src={images.checkicon} alt="" />
            </div>
            <div className="about-textBox">
              <h3>Personlig service</h3>
              <p className="text">
                Vi erbjuder alla våra kunder en personlig upplevelse genom att
                tilldela en fast kontaktperson på företaget. Vi håller våra kunder
                uppdaterade om alla steg vi tar för att främja deras
                intressen. Trots att våra tjänster till största del erbjuds
                digitalt, så ser vi till att alla våra kunder känner sig väl
                omhändertagna och får ett bra och personligt bemötande.
              </p>
            </div>
          </div>
          <div className="about-flexContent">
            <div className="checkicon">
              <img src={images.checkicon} alt="" />
            </div>
            <div className="about-textBox">
              <h3>Omfattande kompetens</h3>
              <p className="text">
                Vi erbjuder en bred kompetens inom diverse områden, bland annat
                administration, juridik, rekrytering, skattefrågor, kundsupport,
                influencer marketing frågor, egenanställning och marknadsföring.
              </p>
            </div>
          </div>
          <div className="about-flexContent">
            <div className="checkicon">
              <img src={images.checkicon} alt="" />
            </div>
            <div className="about-textBox">
              <h3>Gedigen erfarenhet</h3>
              <p className="text">
                Vårt team har omfattande erfarenhet inom branschen och 
                strävar ständigt efter att vidareutbilda oss och utveckla 
                vår kompetens för att alltid vara uppdaterade med nya 
                regler och lagar samt kunna leverera resultat av högsta kvalitet.
              </p>
            </div>
          </div>

          <div className="about-extraText">
            <p>
              Vid frågor eller funderingar tveka inte att höra av er så gör vi
              vårt bästa för att tillgodose era behov och komma fram till en
              lösning som passar er.
            </p>
          </div>

          <div className="about-Btn">
            <Link to="/kontakta-oss">
              <button>Kontakta oss</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
