import React, { useState } from "react";
import { images } from "../constants";
import { Link } from "react-router-dom";
import { MdExpandMore } from "react-icons/md";
import { MdExpandLess } from "react-icons/md";
import "./Egenanstallda.css";
import EgenanställdaAnswers from "./EgenanställdaAnswers";

const Egenanställda = () => {
  const [toggleActive, setToggleActive] = useState("");
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected === i) {
      return setSelected(null);
    }
    setSelected(i);
  };

  return (
    <div className="egenanstallda">
      <div className="egenanstallda__header">
        <div className="egenanstallda__content">
          <h1 className="egenanstallda__title animate__animated animate__zoomIn ">
            Egenanställning
          </h1>

          <p className="egenanstallda__text animate__animated animate__fadeInUp animate__slow	1s ">
            Vi erbjuder influencers, kreatörer och frilansare en egenanställning
            hos oss på RL Deskservice. För att använda våra tjänster och börja
            fakturera via oss behöver du kontakta oss så skapar vi ett avtal. Vi
            svarar vanligen inom 1 arbetsdag.
          </p>
          <div className="egenanstallda__btn animate__animated animate__zoomIn animate__slow	2s ">
            <Link to="/kontakt">
              <button>Kontakta oss</button>
            </Link>
          </div>
        </div>
        <div className="image__gradient"></div>
      </div>

      <div className="egenanstallda__info">
        <div className="egenanstallda__infoContent">
          <h4>RL Deskservice</h4>
          <h2 className="headingTwo">Egenanställning</h2>
          <p className="text">
            Via oss kan du enkelt fakturera dina kunder utan att behöva starta
            ett företag. Vi sköter all administration och fakturering,
            inbetalning av skatter, arbetsgivaravgifter och moms. Därefter
            betalar vi ut din lön till dig, skattad och klar.
          </p>
          <h3>Vad är en egenanställning?</h3>
          <p className="text">
            En egenanställning faller under en så kallad Särskild
            visstidsanställning. Detta innebär att egenanställningsföretaget
            agerar som en arbetsgivare för dig. Du som egenanställd ansvarar
            själv för att söka kunder och uppdrag. Egenanställningsföretaget
            sköter de administrativa aspekterna så som fakturering, löner och
            inbetalning av skatter mot en viss provision. Vi har en av de lägsta
            avgifterna på marknaden just du och detta är för att vi vill göra
            det så lönsamt som möjligt för våra kunder. Därför tar vi på RL
            Deskservice en provision på 2,50% per faktura.
            Egenanställningsföretaget ska vara registrerat för F-skatt, vilket
            vi självklart är. När ditt uppdrag är klart så upphör din
            anställningen hos oss.
          </p>
          <p className="text">
            Vi brukar rekommendera att när man väl börjar fakturera större
            summor per månad regelbundet att då starta ett eget företag. Detta
            eftersom fördelarna är fler vid sådana tillfällen. En fördel är att
            man då kan man fördela intäkterna bättre över flera månader. Om man
            exempelvis fakturerar ena månaden 30 000 kr och andra månaden 0 kr
            så kan man som egenföretagare fördela de intäkterna på flera månader
            och då slipper man ta ut hela summan direkt och själv ansvara för
            planering av pengarna. Däremot om man fakturerar lite lägre summor
            så är egenanställning mer fördelaktigt. Självklart hjälper vi våra
            kunder vid båda situationerna och vi rådger våra kunder utifrån vad
            som är bäst för dem.
          </p>
          <h3>Hur fakturerar jag via er?</h3>
          <p className="text">
            För att du som egenanställd ska kunna fakturera via oss behöver det
            finnas ett egenanställningsavtal. Detta anses vara likt ett vanligt
            anställningsavtal och kan vara som minst i 1 dag och som högst i 30
            dagar hos oss på RL Deskservice.
          </p>
          <p className="text">
            Du fakturerar enkelt via oss genom att skicka ett mail till vår
            e-post med information om uppdraget. Därefter skickar vi ett avtal
            till dig som du signerar. När avtalet väl är signerat skickar vi ut
            fakturan till uppdragsgivaren och när fakturan är inbetald så
            betalar vi ut din lön.
          </p>
          <h3>Du bör alltid skicka in följande fakturaunderlag till oss:</h3>
          <ul>
            <li>Företagets namn</li>
            <li>Organisationsnummer</li>
            <li>
              Ditt arvode och hur mycket som ska faktureras exkl. moms. Vi
              lägger till momsen.
            </li>
            <li>Fakturadatum och betalningsvillkor</li>
            <li>Var fakturan skall skickas</li>
          </ul>
        </div>
      </div>

      <div className="icons-section ">
        <div className="icon-container">
          <div className="icon-content">
            <div className="icon-info">
              <img className="icon-img" src={images.ikon1} alt="" />

              <div className="icon-text">Uppdrag</div>
              <p className="text">
                När du har ett nytt uppdrag och du vill fakturera för uppdraget
                utan eget företag.
              </p>
            </div>
          </div>
          <div className="icon-content">
            <div className="icon-info">
              <img className="icon-img" src={images.ikon2} alt="" />

              <div className="icon-text">Faktura</div>
              <p className="text">
                Du skickar informationen till oss via e-post. Du slipper all
                administration, utan överlämnar allt till oss.
              </p>
            </div>
          </div>
          <div className="icon-content">
            <div className="icon-info">
              <img className="icon-img" src={images.ikon3} alt="" />

              <div className="icon-text">Lön</div>
              <p className="text">
                Din lön betalas ut, skattad och klar, inom 24 timmar efter att
                din kund har betalat fakturan.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="egenanstallda__info">
        <div className="egenanstallda__infoContent mobile-hidden">
          <h2 className="headingTwo">Frågor och svar</h2>

          <h3>Vad händer om din faktura inte blir betald?</h3>
          <p className="text">
            När du använder våra tjänster ansvarar vi för att fakturera dina
            kunder. Skulle betalningen dröja från kundens sida, så skickar vi ut
            påminnelser till kunden. Vi kan även komma att ta kontakt med kunden
            direkt på dina vägnar för att se till så att fakturan blir betald.
            Skulle det krävas så driver vi även en utebliven betalning till
            inkassobolag. Vi hjälper även våra kunder med att driva betalning
            längre om nödvändigt. Vårt mål är att hjälpa dig igenom hela
            processen och se till så att du får den stöttningen du behöver.
          </p>

          <h3>Vad tar RL Deskservice för avgift?</h3>
          <p className="text">
            Vi tar en avgift på 2,50% per faktura som du fakturerar via oss.
          </p>

          <h3>Hur snabbt får jag pengarna?</h3>
          <p className="text">
            Vi betalar ut din lön, skattad och klar, när din kund har betalat
            faktuan. Lönen når ditt bankkonto inom 1-2 arbetsdagar efter
            inbetald faktura.
          </p>

          <h3>Har jag rätt till A-kassa?</h3>
          <p className="text">
            Ja, du har rätt till a-kassa om du uppfyller villkoren i
            arbetslöshetsförsäkringen. Reglerna för a-kassa regleras i lagen om
            arbetslöshetsförsäkring.
          </p>

          <h3>Får jag lönebesked via er?</h3>
          <p className="text">
            Ja, vi skickar alltid ut lönebesked till dig i samband med din lön.
          </p>
          <h3>Ökar eller minskar er avgift beroende på fakturasumman?</h3>
          <p className="text">
            Nej, vi har en fast avgift på 2,50% oavsett hur stor eller liten
            summan är.
          </p>
          <h3>Kan jag fakturera mot privatpersoner också?</h3>
          <p className="text">
            Ja, du kan självklart fakturera en privatperson via oss. Det funkar
            på samma sätt som att fakturera ett företag.
          </p>
          <h3>
            Kommer ni ha en digital tjänst där jag kan fakturera via er hemsida?
          </h3>
          <p className="text">
            Vi kommer inom kort implementera en tjänst på vår hemsida där du
            enkelt kan gå in och fylla i informationen och skicka in den. Vår
            tjänst kommer bli mer automatiserad men du som egenanställd kommer
            fortfarande ha tillgång kostnadsfri rådgivning hos oss.
          </p>
          <h3>
            Kan jag använda era tjänster även om jag har betalningsanmärkningar?
          </h3>
          <p className="text">
            Vi utför inga kreditkontroller eller upplysningar på våra
            egenanställda. Det går bra att fakturera via oss oavsett om du har
            betalningsanmärkningar eller inte.
          </p>
          <h3>Kan jag fakturera utländska företag?</h3>
          <p className="text">
            Det går alldeles utmärkt att fakturera utländska företag via oss.
          </p>
        </div>
      </div>

      <div className="wrapper-mobile">
        <h2 className="headingTwo">Frågor och svar</h2>
        <div className="mobile-infoContainer">
          {toggleActive !== "question1" ? (
            <div className="mobile-infoContent">
              <div
                onClick={() => {
                  setToggleActive("question1");
                }}
                className="mobile-infoTitle"
              >
                <h3>Vad händer om din faktura inte blir betald?</h3>
              </div>
            </div>
          ) : (
            <div className="mobile-infoContent">
              {toggleActive === "question1" ? (
                <div
                  onClick={() => setToggleActive(false)}
                  className="mobile-infoTitle"
                >
                  <MdExpandLess className="mobile-infoIcon" />
                  <h2>Vad händer om din faktura inte blir betald?</h2>
                </div>
              ) : (
                <MdExpandMore className="mobile-infoIcon" />
              )}

              <div className="mobile-textcontainer">
                <EgenanställdaAnswers answer="När du använder våra tjänster ansvarar vi för att fakturera dina kunder. Skulle betalningen dröja från kundens sida, så skickar vi ut påminnelser till kunden. Vi kan även komma att ta kontakt med kunden direkt på dina vägnar för att se till så att fakturan blir betald. Skulle det krävas så driver vi även en utebliven betalning till inkassobolag. Vi hjälper även våra kunder med att driva betalning längre om nödvändigt. Vårt mål är att hjälpa dig igenom hela processen och se till så att du får den stöttningen du behöver." />
              </div>
            </div>
          )}
        </div>
        <div className="mobile-infoContainer">
          {toggleActive !== "question2" ? (
            <div className="mobile-infoContent">
              <div
                onClick={() => {
                  setToggleActive(true);
                  setToggleActive("question2");
                }}
                className="mobile-infoTitle"
              >
                <MdExpandMore className="mobile-infoIcon" />
                <h2>Vad tar RL Deskservice för avgift?</h2>
              </div>
            </div>
          ) : (
            <div className="mobile-infoContent">
              <div
                onClick={() => setToggleActive(false)}
                className="mobile-infoTitle"
              >
                {toggleActive === "question2" ? (
                  <MdExpandLess className="mobile-infoIcon" />
                ) : (
                  <MdExpandMore className="mobile-infoIcon" />
                )}
                <h2>Vad tar RL Deskservice för avgift?</h2>
              </div>

              <div className="mobile-textcontainer">
                {toggleActive === "question2" && (
                  <EgenanställdaAnswers answer="Vi tar en avgift på 2,50% per faktura som du fakturerar via oss." />
                )}
              </div>
            </div>
          )}
        </div>
      </div>

      <div className="wrapper">
        <h2 className="headingTwo">Frågor och svar</h2>
        <div className="accordian">
          {questionsData.map((item, i) => (
            <div className="accordian-item">
              <div className="accordian-title" onClick={() => toggle(i)}>
                {selected === i ? (
                  <MdExpandLess className="mobile-infoIcon" />
                ) : (
                  <MdExpandMore className="mobile-infoIcon" />
                )}

                <h3>{item.question}</h3>
              </div>
              <div
                className={
                  selected === i
                    ? "accordian-content show"
                    : "accordian-content"
                }
              >
                {item.answer}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

const questionsData = [
  {
    question: "Vad händer om din faktura inte blir betald?",
    answer:
      "När du använder våra tjänster ansvarar vi för att fakturera dina kunder. Skulle betalningen dröja från kundens sida, så skickar vi ut påminnelser till kunden. Vi kan även komma att ta kontakt med kunden direkt på dina vägnar för att se till så att fakturan blir betald. Skulle det krävas så driver vi även en utebliven betalning till inkassobolag. Vi hjälper även våra kunder med att driva betalning längre om nödvändigt. Vårt mål är att hjälpa dig igenom hela processen och se till så att du får den stöttningen du behöver.",
  },
  {
    question: "Vad tar RL Deskservice för avgift?",
    answer: "Vi tar en avgift på 2,50% per faktura som du fakturerar via oss.",
  },
  {
    question: "Hur snabbt får jag pengarna?",
    answer:
      "Vi betalar ut din lön, skattad och klar, när din kund har betalat faktuan. Lönen når ditt bankkonto inom 1-2 arbetsdagar efter inbetald faktura.",
  },
  {
    question: "Har jag rätt till A-kassa?",
    answer:
      "Ja, du har rätt till a-kassa om du uppfyller villkoren i arbetslöshetsförsäkringen. Reglerna för a-kassa regleras i lagen om arbetslöshetsförsäkring.",
  },

  {
    question: "Får jag lönebesked via er?",
    answer:
      "Ja, vi skickar alltid ut lönebesked till dig i samband med din lön.",
  },
  {
    question: "Ökar eller minskar er avgift beroende på fakturasumman?",
    answer:
      "Nej, vi har en fast avgift på 2,50% oavsett hur stor eller liten summan är.",
  },
  {
    question: "Kan jag fakturera mot privatpersoner också?",
    answer:
      "Ja, du kan självklart fakturera en privatperson via oss. Det funkar på samma sätt som att fakturera ett företag.",
  },
  {
    question:
      "Kommer ni ha en digital tjänst där jag kan fakturera via er hemsida?",
    answer:
      "Vi kommer inom kort implementera en tjänst på vår hemsida där du enkelt kan gå in och fylla i informationen och skicka in den. Vår tjänst kommer bli mer automatiserad men du som egenanställd kommer fortfarande ha tillgång kostnadsfri rådgivning hos oss.",
  },
  {
    question:
      "Kan jag använda era tjänster även om jag har betalningsanmärkningar?",
    answer:
      "Vi utför inga kreditkontroller eller upplysningar på våra egenanställda. Det går bra att fakturera via oss oavsett om du har betalningsanmärkningar eller inte.",
  },
  {
    question: "Kan jag fakturera utländska företag?",
    answer: "Du kan fakturera företag inom EU/EES via oss.",
  },
];

export default Egenanställda;
