import React, { useState } from "react";
import { Link } from "react-router-dom";
import { images } from "../constants";
import "./Cvpb.css";

const Cvpb = () => {
  return (
    <section className="cv-pb">
      <div className="cv-pb__header">
        <div className="cv-pb__content">
          <h1 className="cv-pbHeader__title animate__animated animate__zoomIn ">
            CV & Personligt brev
          </h1>
          <p className="cv-pb__text animate__animated animate__fadeInUp animate__slow	1s">
            Vi hjälper dig skapa ett skräddarsytt CV och personligt brev som
            fångar rekryterares och arbetsgivares uppmärksamhet.
          </p>
        </div>
        <div className="image__gradient"></div>
      </div>

      <div className="cv-pb__about">
        <div className="cv-pb__column">
          <h3>RL Deskservice</h3>
          <h2 className="headingTwo">CV & Personligt Brev</h2>
          <p className="text">
            Ett CV är en arbetsgivare eller rekryterares "första intryck" av dig
            och ett första intryck är väldigt viktigt, speciellt i sammanhang då
            man söker arbete. Därför ska man alltid lägga stor vikt på att
            finslipa sitt CV och utforma det så att det bäst framhäver ens goda
            egenskaper. Vid frågor eller funderingar, skicka gärna ett email
            till oss!
          </p>
          <h4>Lauras Deskservice blir en del av RL Deskservice</h4>
          <p className="text">
            Efter att ha arbetat med skräddarsydda CV:n och personliga brev i
            flera år och haft över 200+ kunder så har jag nu valt att slå ihop
            mig med andra administratörer och visionärer för att utvidga
            företaget så att våra kunder kan få en allsidig administrativ hjälp
            på ett och samma ställe.
          </p>

          <div className="cv-pb__aboutBtn">
            <Link to="/kontakta-oss">
              <button>Kontakta oss</button>
            </Link>
          </div>
        </div>
        <div className="cv-pb__columnImg">
          <div className="cv-pb__aboutImage">
            <img src={images.resumeimg2} alt="" />
          </div>
        </div>
      </div>

      <div className="cv-container">
        <div className="cv-img">
          <img src={images.cvimage} alt="" />
        </div>
        <div className="cv-info">
          <h2>Vad är ett CV?</h2>
          <p className="text">
            CV står för Curriculum Vitae, och är en sammanfattning av din
            arbetslivserfarenhet, din kompetens, din utbildning och dina
            färdigheter. Ett CV är där du framför din erfarenhet och dina
            meriter. Det är där rekryterare eller arbetsgivare snabbt kan
            identifiera om du passar tjänsten de försöker tillsätta eller inte.
            Det är därför viktigt att ha ett CV med lockande layout och där det
            är lätt att snabbt hitta relevant information. Ditt CV och ditt
            personliga brev är rekryterarens eller arbetsgivarens första intryck
            av dig och det är därför väldigt viktigt att du tidigt framhäver
            varför just du är en attraktiv kandidat för tjänsten som du söker.
            Syftet med ditt CV och ditt personliga brev är som sagt att väcka
            rekryterarens eller arbetsgivarens intresse och bli kallad till
            intervju. Det är viktigt att du kontinuerligt uppdaterar ditt CV med
            dina nya erfarenheter. Samma gäller din LinkedIn profil.{" "}
          </p>
        </div>
      </div>
      <div className="cv-infocontainer">
        <div className="cv-info">
          <h2>Vad ska ett CV innehålla?</h2>
          <p className="text">
            Ett CV ska innehålla sammanfattad information om dig och din
            erfarenhet. Det är därför viktigt att du får med följande:
          </p>
          <ul className="cv-info__list">
            <li>
              <span>Kontaktinformation:</span> Placera alltid din
              kontaktinformation längst upp och gör det så lätt att hitta som
              möjligt.{" "}
            </li>
            <li>
              <span>En kort introduktion:</span> Vem är du och vad är du ute
              efter?
            </li>
            <li>
              <span>Din arbetslivserfarenhet:</span> Försök begränsa det till
              dina 3 sista anställningar. Det är viktigt att du får med följande
              på varje tjänst du inkluderar:
              <ul className="nested-list">
                <li>
                  Årtal vid start av tjänst och årtal vid avslut. Om tjänsten är
                  pågående ska du göra det tydligt. Dock räcker det med att
                  skriva exempelvis: “2022 - “.
                </li>
                <li>Företag och ort.</li>
                <li>Befattning/yrkestitel.</li>
                <li>Kort information om dina arbetsuppgifter.</li>
              </ul>
            </li>
            <li>
              <span>Din utbildning:</span> Du bör alltid undvika att inkludera
              grundskola och liknande utbildningar. Har du läst fler
              utbildningar på högre nivå behöver du inte inkludera
              gymnasieutbildning om inte det är direkt relevant till tjänsterna
              du vill söka. Det är viktigt att du inkluderar:
              <ul className="nested-list">
                <li>
                  Årtal då du började på utbildningen och årtal när du slutade.
                  Om utbildningen är pågående ska du göra det tydligt.
                </li>
                <li>Lärosäte och ort.</li>
                <li>Inriktning, program och examen.</li>
                <li>Kort beskrivning av din utbildning.</li>
              </ul>
            </li>
            <li>
              <span>Kompetens:</span>

              <ul className="nested-list">
                <li>Språk och dina kunskaper.</li>
                <li>
                  IT-kompetens. Inkludera de system och program du har kunskap
                  inom.
                </li>
                <li>Innehav av körkort, truckkort etc.</li>
              </ul>
            </li>
          </ul>
          <p className="text">
            På vår webbshop finner du färdiga CV mallar som är utformade för att
            locka rekryterare och arbetsgivare samt optimera ditt jobbsökande.{" "}
          </p>
        </div>
        <div className="cv-img">
          <img src={images.cvimage4} alt="" />
        </div>
      </div>
      <div className="cv-mobile__container">
        <div className="cv-img">
          <img src={images.cv1} alt="" />
        </div>
        <div className="cv-info">
          <h2>Vad är ett CV?</h2>
          <p className="text">
            CV står för Curriculum Vitae, och är en sammanfattning av din
            arbetslivserfarenhet, din kompetens, din utbildning och dina
            färdigheter. Ett CV är där du framför din erfarenhet och dina
            meriter. Det är där rekryterare eller arbetsgivare snabbt kan
            identifiera om du passar tjänsten de försöker tillsätta eller inte.
            Det är därför viktigt att ha ett CV med lockande layout och där det
            är lätt att snabbt hitta relevant information. Ditt CV och ditt
            personliga brev är rekryterarens eller arbetsgivarens första intryck
            av dig och det är därför väldigt viktigt att du tidigt framhäver
            varför just du är en attraktiv kandidat för tjänsten som du söker.
            Syftet med ditt CV och ditt personliga brev är som sagt att väcka
            rekryterarens eller arbetsgivarens intresse och bli kallad till
            intervju. Det är viktigt att du kontinuerligt uppdaterar ditt CV med
            dina nya erfarenheter. Samma gäller din LinkedIn profil.{" "}
          </p>
        </div>
        <div className="cv-img">
          <img src={images.cv2} alt="" />
        </div>
        <div className="cv-info">
          <h2>Vad ska ett CV innehålla?</h2>
          <p className="text">
            Ett CV ska innehålla sammanfattad information om dig och din
            erfarenhet. Det är därför viktigt att du får med följande:
          </p>
          <ul className="cv-info__list">
            <li>
              <span>Kontaktinformation:</span> Placera alltid din
              kontaktinformation längst upp och gör det så lätt att hitta som
              möjligt.{" "}
            </li>
            <li>
              <span>En kort introduktion:</span> Vem är du och vad är du ute
              efter?
            </li>
            <li>
              <span>Din arbetslivserfarenhet:</span> Försök begränsa det till
              dina 3 sista anställningar. Det är viktigt att du får med följande
              på varje tjänst du inkluderar:
              <ul className="nested-list">
                <li>
                  Årtal vid start av tjänst och årtal vid avslut. Om tjänsten är
                  pågående ska du göra det tydligt. Dock räcker det med att
                  skriva exempelvis: “2022 - “.
                </li>
                <li>Företag och ort.</li>
                <li>Befattning/yrkestitel.</li>
                <li>Kort information om dina arbetsuppgifter.</li>
              </ul>
            </li>
            <li>
              <span>Din utbildning:</span> Du bör alltid undvika att inkludera
              grundskola och liknande utbildningar. Har du läst fler
              utbildningar på högre nivå behöver du inte inkludera
              gymnasieutbildning om inte det är direkt relevant till tjänsterna
              du vill söka. Det är viktigt att du inkluderar:
              <ul className="nested-list">
                <li>
                  Årtal då du började på utbildningen och årtal när du slutade.
                  Om utbildningen är pågående ska du göra det tydligt.
                </li>
                <li>Lärosäte och ort.</li>
                <li>Inriktning, program och examen.</li>
                <li>Kort beskrivning av din utbildning.</li>
              </ul>
            </li>
            <li>
              <span>Kompetens:</span>

              <ul className="nested-list">
                <li>Språk och dina kunskaper.</li>
                <li>
                  IT-kompetens. Inkludera de system och program du har kunskap
                  inom.
                </li>
                <li>Innehav av körkort, truckkort etc.</li>
              </ul>
            </li>
          </ul>
        </div>
        <div className="cv-img">
          <img src={images.pb} alt="" />
        </div>
        <div className="cv-info">
          <h2>Vad är ett personligt brev?</h2>
          <p className="text">
            Ett personligt brev är ett komplement till ditt CV och en närmare
            presentation av dig och din erfarenhet. Ditt personliga brev bör
            inte överskrida en sida. Målet med ditt personliga brev är, likt
            ditt CV, att locka rekryterarens eller arbetsgivarens intresse och
            bli kallad på intervju. Texten i ditt personliga brev ska vara
            lättläst men professionell. Försök att locka läsaren genom att
            framföra dig själv på ett passande och ödmjukt sätt. Du bör, likt
            ditt CV, inkludera kontaktinformation på ett ställe som är lätt att
            hitta. Ett tips är att alltid skicka in ditt CV och personliga brev
            i PDF-format. Ett personligt brev bör anpassas efter varje tjänst du
            söker. Detta då ett generellt personligt brev ofta tyder på just
            det, ett generellt personligt brev. Många rekryterare kan snabbt
            identifiera detta och det i sin tur minskar sannolikheten för dig
            att bli kallad på intervju. Mottagaren av ditt CV och personliga
            brev vill gärna se att du har lagt tid och energi på att framföra
            dig själv på ett välformulerat och professionellt sätt, samt att du
            har ett genuint intresse för just den tjänsten de försöker
            tillsätta. Vi på RL Deskservice AB rekommenderar att du har en så
            kallad stomme till ditt personliga brev och sedan bygger på det
            utifrån tjänsten och branschen du är intresserad av. Detta
            underlättar för dig i ditt arbetssökande och stärker dina chanser
            till intervju. Ett tips är att kolla på kvalifikationskraven i varje
            annons och försöka få med de kriterierna som du uppnår i ditt
            personliga brev.
          </p>
        </div>
      </div>
      <div className="cv-container">
        <div className="cv-img">
          <img src={images.resumeimg3} alt="" />
        </div>
        <div className="cv-info">
          <h2>Vad är ett personligt brev?</h2>
          <p className="text">
            Ett personligt brev är ett komplement till ditt CV och en närmare
            presentation av dig och din erfarenhet. Ditt personliga brev bör
            inte överskrida en sida. Målet med ditt personliga brev är, likt
            ditt CV, att locka rekryterarens eller arbetsgivarens intresse och
            bli kallad på intervju. Texten i ditt personliga brev ska vara
            lättläst men professionell. Försök att locka läsaren genom att
            framföra dig själv på ett passande och ödmjukt sätt. Du bör, likt
            ditt CV, inkludera kontaktinformation på ett ställe som är lätt att
            hitta. Ett tips är att alltid skicka in ditt CV och personliga brev
            i PDF-format. Ett personligt brev bör anpassas efter varje tjänst du
            söker. Detta då ett generellt personligt brev ofta tyder på just
            det, ett generellt personligt brev. Många rekryterare kan snabbt
            identifiera detta och det i sin tur minskar sannolikheten för dig
            att bli kallad på intervju. Mottagaren av ditt CV och personliga
            brev vill gärna se att du har lagt tid och energi på att framföra
            dig själv på ett välformulerat och professionellt sätt, samt att du
            har ett genuint intresse för just den tjänsten de försöker
            tillsätta. Vi på RL Deskservice AB rekommenderar att du har en så
            kallad stomme till ditt personliga brev och sedan bygger på det
            utifrån tjänsten och branschen du är intresserad av. Detta
            underlättar för dig i ditt arbetssökande och stärker dina chanser
            till intervju. Ett tips är att kolla på kvalifikationskraven i varje
            annons och försöka få med de kriterierna som du uppnår i ditt
            personliga brev.
          </p>
        </div>
      </div>

      <div className="cv-pb__info">
        <div className="cv-infoContent">
          <h3>Skräddarsytt CV & personligt brev</h3>
          <h2 className="headingTwo ">Så fungerar våra tjänster</h2>
          <p className="text">
            Vid köp av tjänster så får du ett bekräftelsemail där all
            information finns samt hur processen går till. I det mailet finns
            information om hur du skickar ditt befintliga CV och personligt
            brev, alternativt information om hur Linkedin processen ser ut.
            Skulle du sakna ett befintligt CV och/eller personligt brev så
            kommer du få en länk till ett formulär som du kan fylla i.
          </p>
          <p className="text cvpb__text">
            Det är viktigt för dig att tidigt i processen klargöra vilka
            förväntningar du har samt de önskemål eller idéer som du har
            gällande dina dokument. Dela gärna med dig av dina förväntningar
            kring layout, innehåll och liknande. Det är bättre att säga för
            mycket än för lite! Målet i detta arbete är att skapa ett CV
            och/eller ett personligt brev som speglar dig som kund så mycket som
            möjligt. Detta innebär att jag försöker få med ord, fraser och
            formuleringar som du själv använder i din kommunikation med mig för
            att bäst ta mig an rollen som "spökskrivare".
          </p>
          <p className="text cvpb__text">
            När all information har inkommit så skapar jag ett utkast till dig
            som kund, utifrån den informationen jag får. Observera att jag inte
            tillägger egen information, utan utgår enbart ifrån informationen
            jag får av dig. När utkastet är klart så skickas det till dig för
            feedback. Det är då viktigt att du som kund återkommer med
            eventuella tillägg, ändringar, frågor, etc. Detta innebär att
            dokumentet inte anses vara klart i detta stadie. Det är väldigt
            viktigt att du återkommer med de ändringarna du vill ha gjort för
            att jag ska kunna leverera ett CV och/eller ett personligt brev som
            du känner dig nöjd med.
          </p>
          <p className="text cvpb__text">
            Varför processen ser ut på det här sättet är för att ett CV och
            personligt brev är personliga dokument som ska spegla din karaktär
            och erfarenhet. Uppdraget tas på fullt allvar och vi strävar
            konstant efter att kunden ska bli nöjd.
          </p>

          <div className="cv-pb__aboutBtn">
            <Link to="/kontakta-oss">
              <button>Kontakta oss</button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Cvpb;
