import React from "react";
import { Link } from "react-router-dom";
import { images } from "../constants";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { MdKeyboardArrowRight } from "react-icons/md";
import { MdKeyboardArrowLeft } from "react-icons/md";
import "animate.css/animate.min.css";
import { AnimationOnScroll } from "react-animation-on-scroll";
import Typed from "react-typed";
import "./Home.css";

const Home = () => {
  const SampleNextArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="arrow__btn" onClick={onClick}>
        <button className="next">
          <MdKeyboardArrowRight className="arrow__icon" />
        </button>
      </div>
    );
  };

  const SamplePrevArrow = (props) => {
    const { onClick } = props;
    return (
      <div className="arrow__btn" onClick={onClick}>
        <button className="prev">
          <MdKeyboardArrowLeft className="arrow__icon" />
        </button>
      </div>
    );
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    autoplaySpeed: 3000,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,

    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },

      {
        breakpoint: 700,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
    ],
  };

  return (
    <section id="home" className="home">
      <div className="home__container">
        <Carousel
          autoPlay={true}
          infiniteLoop
          showStatus={false}
          showIndicators={false}
          showThumbs={false}
          interval={10000}
          preventMovementUntilSwipeScrollTolerance={true}
          swipeScrollTolerance={50}
        >
          <div className="carousel__images">
            <picture>
              <source media="(max-width: 600px)" srcset={images.homeimage} />
              <img
                loading="lazy"
                className="home__image"
                src={images.homeimage}
              />
            </picture>
            <div className="home__content ">
              <h1 className="home__title animate__animated animate__fadeInUp  ">
                Administration för <br />
                <span>
                  <Typed
                    strings={[
                      " Företag",
                      " privatpersoner",
                      " kreatörer",
                      " frilansare",
                    ]}
                    typeSpeed={50}
                    backSpeed={50}
                    loop={true}
                    showCursor={true}
                    delay={2000}
                  />
                </span>
              </h1>

              <p className="home__text animate__animated animate__fadeInUp animate__slow	1s">
                Vi tar oss an det administrativa, så ni slipper göra det.
              </p>

              <div className="home__btn animate__animated animate__zoomIn animate__slow	2s ">
                <Link to="/kontakta-oss" key={"6"} id={"6"}>
                  <button>Kontakta oss</button>
                </Link>
              </div>
            </div>
            <div className="image__gradient"></div>
          </div>
          <div className="carousel__images">
            <img
              loading="lazy"
              className="home__image"
              src={images.Företaghome}
              alt=""
            />
            <div className="home__content  ">
              <h1 className="home__title ">
                Administration <br /> för företag
              </h1>

              <p className="home__text ">
                Administrativa lösningar till företag som behöver assistans inom
                bland annat fakturering, bokföring och bokslut, avtalshantering
                och migrationsärenden.
              </p>

              <div className="home__btn ">
                <Link to="/företag" id={"2"}>
                  <button>Läs mer</button>
                </Link>
              </div>
            </div>
            <div className="image__gradient"></div>
          </div>
          <div className="carousel__images">
            <img
              loading="lazy"
              className="home__image"
              src={images.privatpersonerhome}
              alt=""
            />
            <div className="home__content">
              <h1 className="home__title">
                Administration för <br /> privatpersoner
              </h1>

              <p className="home__text">
                privatpersoner assistans och rådgivning inom de flesta
                administrativa områdena.
              </p>

              <div className="home__btn">
                <Link to="/privat-Personer">
                  <button>Läs mer</button>
                </Link>
              </div>
            </div>
            <div className="image__gradient"></div>
          </div>
          <div className="carousel__images">
            <img
              loading="lazy"
              className="home__image"
              src={images.egenanställdahome}
              alt=""
            />
            <div className="home__content">
              <h1 className="home__title">
                Administration för <br /> egenanställda
              </h1>

              <p className="home__text">
                Vi hjälper även Influencers med företagsadministration och/eller
                privat administration, inom bland annat fakturering, resebokning
                och planering, kundservice och mycket annat.
              </p>

              <div className="home__btn">
                <Link to="/egenanställda">
                  <button>Läs mer</button>
                </Link>
              </div>
            </div>
            <div className="image__gradient"></div>
          </div>
          <div className="carousel__images">
            <img
              loading="lazy"
              className="home__image"
              src={images.cvpbhome}
              alt=""
            />
            <div className="home__content">
              <h1 className="home__title">
                CV & Personligt <br /> brev
              </h1>

              <p className="home__text">
                Vi hjälper dig skapa ett CV och personligt brev som fångar
                arbetsgivares uppmärksamhet!
              </p>

              <div className="home__btn">
                <Link to="/cv-personligtBrev">
                  <button>Läs mer</button>
                </Link>
              </div>
            </div>
            <div className="image__gradient"></div>
          </div>
        </Carousel>
      </div>

      <div className="home__about">
        <div className="homeAbout__left">
          <h3>Välkommen till</h3>

          <h2 className="headingTwo">RL Deskservice</h2>

          <p className="text">
            Som ett allsidigt konsultföretag hjälper vi dig med all form av
            administration. Vi erbjuder skräddarsydda administrativa tjänster
            till företag, privatpersoner och influencers/kreatörer som matcher
            just deras behov. Vårt team består av flera administratörer med över
            25+ års gemensam erfarenhet inom administration, bokföring,
            rekrytering, juridik, marknadsföring och mycket annat.
          </p>
          <p className="text">
            Vi erbjuder även professionellt stöd till både företag och
            privatpersoner som behöver hjälp med olika typer av
            migrationsansökningar och tillstånd. Oavsett om det handlar om att
            ansöka om arbetstillstånd för internationell personal,
            uppehållstillstånd för familjeåterförening, eller hjälp med att
            förnya ett befintligt tillstånd, finns vi här för att göra processen
            smidigare och mindre stressande. Vi guidar dig genom varje steg,
            från att samla in rätt dokument till att säkerställa att alla krav
            uppfylls. Med vår expertis och personliga service kan du känna dig
            trygg i att din ansökan hanteras snabbt, korrekt och med största
            omsorg.
          </p>
          <div className="home__aboutBtn ">
            <Link to="/om-oss">
              <button>Läs mer</button>
            </Link>
          </div>
        </div>
        <div className="home__right">
          <div className="home__aboutImage">
            <img src={images.homeimg1} alt="" />
          </div>
        </div>
      </div>

      <div className="home__services ">
        <h3>Vad vi erbjuder</h3>
        <h2 className="headingTwo">Våra tjänster</h2>
        <AnimationOnScroll
          animateIn="animate__fadeIn"
          animateOnce={true}
          duration={1.5}
        >
          <div className="homeServices__container">
            <div className="home__service">
              <img src={images.Företaghome} alt="" />

              <div className="home__serviceContent">
                <h2 className="service__title">
                  Administration för <br /> företag
                </h2>
                <Link to="/företag">
                  <button className="service__button">Läs mer</button>
                </Link>
              </div>
              <div className="homeService__gradient"></div>
            </div>
            <div className="home__service">
              <img src={images.privatpersonerhome} alt="" />
              <div className="home__serviceContent">
                <h2 className="service__title">
                  Administration för <br /> privatpersoner
                </h2>
                <Link to="/privat-Personer">
                  <button className="service__button">Läs mer</button>
                </Link>
              </div>

              <div className="homeService__gradient"></div>
            </div>
            <div className="home__service">
              <img src={images.pb2home} alt="" />
              <div className="home__serviceContent">
                <h2 className="service__title">
                  CV, personligt brev <br /> & Linkedin tjänster
                </h2>
                <Link to="/cv-personligtBrev">
                  <button className="service__button">Läs mer</button>
                </Link>
              </div>
              <div className="homeService__gradient"></div>
            </div>
            <div className="home__service">
              <img src={images.egenanställdahome} alt="" />

              <div className="home__serviceContent">
                <h2 className="service__title">Egenanställda</h2>
                <Link to="/egenanställda">
                  <button className="service__button">Läs mer</button>
                </Link>
              </div>
              <div className="homeService__gradient"></div>
            </div>
            <div className="home__service">
              <img src={images.kreatörerimg} alt="" />

              <div className="home__serviceContent">
                <h2 className="service__title">Kreatörer</h2>
                <Link to="/kreatörer">
                  <button className="service__button">Läs mer</button>
                </Link>
              </div>
              <div className="homeService__gradient"></div>
            </div>
            <div className="home__service">
              <img src={images.webbshopimg} alt="" />

              <div className="home__serviceContent">
                <h2 className="service__title">Vår webbshop</h2>
                <a href="https://rldeskservice.se/webbshop/butik.html">
                  <button className="service__button">Shoppa</button>
                </a>
              </div>
              <div className="homeService__gradient"></div>
            </div>
          </div>
        </AnimationOnScroll>
      </div>

      <div className="home__testimonials">
        <h2>Vad våra kunder säger</h2>
        <Slider {...settings}>
          <div className="home__testimonial">
            <img src={images.qicon} alt="" />
            <h3>INTERVJU</h3>
            <p className="testimonial__text">
              “Gick på riktigt på tio minuter, du är en ängel!”
            </p>
            <p className="testimonial__name">LINN</p>
          </div>
          <div className="home__testimonial">
            <img src={images.qicon} alt="" />
            <h3>INTERVJU</h3>
            <p className="testimonial__text">
              “Vill bara tacka igen för ditt tålamod med mitt CV. Jag har nu
              fått jobbet som jag sökte och så gärna ville ha”
            </p>
            <p className="testimonial__name">SARA</p>
          </div>
          <div className="home__testimonial">
            <img src={images.qicon} alt="" />
            <h3>NÖJD KUND</h3>
            <p className="testimonial__text">
              “Du är ta mig f*n grym på att skapa CV och PB! WOW!”
            </p>
            <p className="testimonial__name">MAJA</p>
          </div>
          <div className="home__testimonial">
            <img src={images.qicon} alt="" />
            <h3>NÖJD KUND</h3>
            <p className="testimonial__text">
              “Är så tacksam för din hjälp, kommer varmt rekommendera sig till
              min omgivning”
            </p>
            <p className="testimonial__name">SOFIA</p>
          </div>
        </Slider>
      </div>
      <div className="icons-section ">
        <div className="icon-container">
          <div className="icon-content">
            <div className="icon-img">
              <img src={images.fasticon} alt="" />

              <div className="icon-title">Snabb service</div>
              <p>
                Vi ser till att alltid prioritera våra kunder och erbjuda en
                snabb och god service.
              </p>
            </div>
          </div>
          <div className="icon-content">
            <div className="icon-img">
              <img src={images.psicon} alt="" />

              <div className="icon-title">Personlig service</div>
              <p>
                Vi erbjuder alla våra kunder en personlig upplevelse med en fast
                kontaktperson på bolaget.
              </p>
            </div>
          </div>
          <div className="icon-content">
            <div className="icon-img">
              <img src={images.experticon} alt="" />

              <div className="icon-title">Bred kompetens</div>
              <p>Vi erbjuder en bred kompetens inom diverse områden.</p>
            </div>
          </div>
          <div className="icon-content">
            <div className="icon-img">
              <img src={images.experienceicon} alt="" />

              <div className="icon-title">Lång erfarenhet</div>
              <p>
                Vårt team har en lång erfarenhet inom yrket, och fortsätter
                kontinuerligt utbilda oss och utveckla vår kompetens.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Home;
