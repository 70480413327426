import React from "react";
import { Link } from "react-router-dom";
import { images } from "../constants";
import ScrollButton from "./ScrollButton";
import { BsInstagram } from "react-icons/bs";
import { FaLinkedinIn } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";

import "./Footer.css";
import Betalningsalternativ from "./Betalningsalternativ";

const Footer = () => {
  return (
    <section className="footer">
      <div className="scroll__button">
        <ScrollButton />
      </div>

      <div className="footer__container">
        <div className="footer__top">
          <div className="footer__content">
            <div className="footer__logo">
              <Link to="/">
                <img className="footer__logoImg" src={images.logo2} alt="" />
              </Link>
            </div>
            <p>
              Vi erbjuder omfattande administrativ assistans till företag och
              privatpersoner. Med vår expertis får du professionellt stöd till
              ett konkurrenskraftigt pris. Oavsett vilken typ av administration
              du behöver, kan vi skräddarsy våra lösningar för att passa just
              era behov.
            </p>
            <img className="signature__img" src={images.rl} alt="" />
          </div>

          <div className="footerNav__container">
            <div className="footer__nav nav__links">
              <h2>RL Deskservice</h2>
              <li>
                <Link className="footer__link" to="/företag">
                  Företag
                </Link>
              </li>
              <li>
                <Link className="footer__link" to="/privat-personer">
                  Privatpersoner
                </Link>
              </li>

              <li>
                <Link className="footer__link" to="/kreatörer">
                  Kreatörer
                </Link>
              </li>
              <li>
                <Link className="footer__link" to="/egenanställda">
                  Egenanställning
                </Link>
              </li>
              <li>
                <Link className="footer__link" to="/cv-personligtBrev">
                  CV & personligt brev
                </Link>
              </li>

              <li>
                <a
                  className="footer__link"
                  href="https://rldeskservice.se/webbshop/butik.html"
                >
                  Webbshop
                </a>
              </li>
            </div>

            <div className="footer__nav nav__links">
              <h2>Kundservice</h2>
              <li>
                <Link className="footer__link" to="/samarbeta-med-oss">
                  Samarbeta med oss
                </Link>
              </li>
              <li>
                <Link className="footer__link" to="/priser">
                  Våra priser
                </Link>
              </li>

              <li>
                <Link className="footer__link" to="/om-oss">
                  Om oss
                </Link>
              </li>
              <li>
                <Link className="footer__link" to="/kontakta-oss">
                  Kontakta oss
                </Link>
              </li>
            </div>
          </div>
          <ul className="footerList-Mobile">
            <li className="footerBottom-list__spc">
              <Link className="footerBottom__link" to="/köpsvilkor">
                Köpsvillkor
              </Link>
            </li>

            <li className="footerBottom-list">
              <Link className="footerBottom__link" to="/integritetspolicy">
                Integritetspolicy
              </Link>
            </li>
          </ul>
          <div className="social__container">
            <div className="footer-social">
              <a
                className="icon-link"
                href="https://www.facebook.com/profile.php?id=100089058472092 "
                target="_blank"
              >
                <FaFacebookF className="footer__icon" />
              </a>
              <a
                className="icon-link"
                href="https://www.instagram.com/rl.deskservice/"
                target="_blank"
              >
                <BsInstagram className="footer__icon" />
              </a>
              <a
                className="icon-link"
                href="https://www.linkedin.com/company/rl-deskservice/"
                target="_blank"
              >
                <FaLinkedinIn className="footer__icon" />
              </a>
            </div>
            <Betalningsalternativ />
          </div>
        </div>
        <div className="social__containerMobile">
          <div className="footer-social">
            <a
              className="icon-link"
              href="https://www.facebook.com/profile.php?id=100089058472092"
              target="_blank"
            >
              <FaFacebookF className="footer__icon" />
            </a>
            <a
              className="icon-link"
              href="https://www.instagram.com/rl.deskservice/"
              target="_blank"
            >
              <BsInstagram className="footer__icon" />
            </a>
            <a
              className="icon-link"
              href="https://www.linkedin.com/company/rl-deskservice/"
              target="_blank"
            >
              <FaLinkedinIn className="footer__icon" />
            </a>
          </div>
          <Betalningsalternativ />
        </div>

        <div className="footer__bottom">
          <p className="footer__copy">
            <span>&#169;</span> 2023 RL Deskservice AB 559382-1936. Alla
            rättigheter förbehållna
          </p>
          <ul className="footerList-links">
            <li className="footerBottom-list">
              <Link className="footerBottom__link" to="/köpsvilkor">
                Köpsvillkor
              </Link>
            </li>
            <li className="footerBottom__list">|</li>
            <li className="footerBottom-list">
              <Link className="footerBottom__link" to="/integritetspolicy">
                Integritetspolicy
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </section>
  );
};

export default Footer;
